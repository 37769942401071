<template>
  <TModal
    title="Purchase inventory"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div></div>
    </template>
    <template #title>
      <TMessage content="Product" bold class="h5 my-auto">
        <template #suffix>
          <span>: {{ product_id }}</span>
        </template>
      </TMessage>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      noPaginate
      store="order.order_items_need_buy"
      resource=""
      noFilter
      noClickRow
    >
      <template #order_id="{ item }">
        <td>
          <TLink
            v-if="item.order"
            :content="item.order_id"
            :messageOptions="{ bold: true }"
            :to="lodash.getReferenceLink('order', item.order_id)"
          />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TLink
            v-if="item.customer"
            :content="item.customer.name"
            :to="lodash.getReferenceLink('user', item.customer.id)"
          />
        </td>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney
            :amount="item.price"
            :currency="item.currency_id || 'JPY'"
          />
        </td>
      </template>
      <template #remaining_distributed_quantity="{ item }">
        <td>
          <TMessageNumber
            :value="item.remaining_distributed_quantity"
            class="h6"
          />
        </td>
      </template>
      <template #quantity="{ item }">
        <td>
          <TMessageNumber :value="item.quantity" class="h6" />
        </td>
      </template>
      <template #distributed_quantity="{ item }">
        <td>
          <TMessageNumber :value="item.distributed_quantity" class="h6" />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import organizationStore from "@/core/services/tomoni/organization.local";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "order_id",
          label: "Order",
          _style: "width: 80px; min-width: 80px",
        },
        {
          key: "customer",
          label: "Customer",
          _style: "width: 200px; min-width: 200px",
        },
        { key: "price", label: "Price" },
        {
          key: "quantity",
          label: "Quantity",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "distributed_quantity",
          label: "Distributed",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "remaining_distributed_quantity",
          label: "Remaining quantity",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 250px; min-width: 250px",
        },
      ],
    };
  },
  watch: {
    product_id: {
      immediate: true,
      handler(value) {
        if (value && this.show) {
          this.$store.dispatch("order.order_items_need_buy.apply-query", {
            "filter[product_id]": value,
            "filter[order.sales_organization_id]": organizationStore.get(),
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.order_items_need_buy.list",
    }),
  },
};
</script>
