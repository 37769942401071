<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.purchase_product_statistics"
      @click-clear-filter="clearFilter"
      reloadable
      @click-reload="refresh"
      resource=""
      noClickRow
    >
      <template #append-actions="{ item }">
        <TButtonQuickView @click="view(item)" class="mx-auto" />
        <TButtonEdit @click="edit(item)" class="mx-auto" />
      </template>
      <template #first_item="{ item }">
        <td>
          <SCardProductItems :item="item.product" resource="goods/products" />
        </td>
      </template>
      <template #supplier="{ item }">
        <td class="text-truncate">
          <div
            v-for="supplier in item.suppliers"
            :key="supplier.id"
            class="mb-2 bg-white p-1 rounded"
          >
            <TLink
              :messageOptions="{ bold: true }"
              :content="supplier.name"
              :to="lodash.getReferenceLink('supplier',supplier.id)"
            />
            <div class="d-flex">
              <CBadge color="light" class="d-flex p-1" style="width: 125px">
                <TMessage content="Quantity" class="">
                  <template #suffix>
                    / {{ $t("Box") }}:
                    <span class="font-weight-bold">{{
                      supplier.pivot.quantity_items
                    }}</span>
                  </template>
                </TMessage>
              </CBadge>
              <TMessage content="Box" class="">
                <template #prefix>
                  (<span class=""
                    >{{
                      Math.ceil(item.output / supplier.pivot.quantity_items)
                    }}
                  </span>
                </template> </TMessage
              >)
            </div>
          </div>
          <TMessageNoData
            iconSize="20"
            spacing="my-0"
            alignment="left"
            size="h4"
            v-if="!item.suppliers.length"
          />
        </td>
      </template>
      <template #output="{ item }">
        <td>
          <TMessageNumber class="h6" :value="item.output" />
        </td>
      </template>
      <template #first_item-filter>
        <TInputText
          :value.sync="filter['product_id']"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
      <template #supplier-filter>
        <SSelectSupplier
          :value="filterSupplier"
          @update:value="updateFilterSupplier"
          @change="filterChange"
          prependAll
          noCustomLabel
          :prepend="[{ id: 'empty', name: $t('Empty') }]"
        />
      </template>
    </TTableAdvance>
    <SModalUpdateProductSupplier
      v-if="productSelected"
      :show.sync="showModalEdit"
      :product_id="productSelected"
      @updated="filterChange"
    />
    <PurchaseInventory
      v-if="productViewSelected"
      :show.sync="showModalView"
      :product_id="productViewSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PurchaseInventory from "./components/PurchaseInventory.vue";

export default {
  components: { PurchaseInventory },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "first_item", label: "Product" },
        {
          key: "output",
          label: "Quantity to buy",
        },
        {
          key: "supplier",
          label: "Supplier",
          _classes: "text-nowrap",
          _style: "width: 570px; min-width: 570px",
        },
      ],
      filter: {},
      filterSupplier: "",
      productSelected: "",
      productViewSelected: "",
      showModalEdit: false,
      showModalView: false,
    };
  },
  beforeMount() {
    this.setStoreFilter();
  },
  mounted() {
    this.$store.dispatch(
      "product.purchase_product_statistics.fetch.if-first-time"
    );
  },
  computed: {
    ...mapGetters({
      list: "product.purchase_product_statistics.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("product.purchase_product_statistics.fetch");
    },
    edit(item) {
      this.productSelected = item.product_id;
      this.showModalEdit = true;
    },
    view(item) {
      this.productViewSelected = item.product_id;
      this.showModalView = true;
    },
    setStoreFilter() {
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
      });
      this.$store.commit("product.purchase_product_statistics.reset-query");
      this.$store.commit(
        "product.purchase_product_statistics.push-query",
        query
      );
    },
    filterChange() {
      this.setStoreFilter();
      this.refresh();
    },
    clearFilter() {
      this.filter = {};
      this.filterSupplier = "";
      this.filterChange();
    },
    updateFilterSupplier(value) {
      this.filterSupplier = value;
      if (value == "empty") {
        this.filter["empty_suppliers"] = 1;
        this.filter["suppliers.id"] = "";
      } else {
        this.filter["suppliers.id"] = value;
        this.filter["empty_suppliers"] = "";
      }
    },
  },
};
</script>
